import styled from "styled-components";
import { MagentaColor } from "./Colors";
import { Spacing } from "./Spacing";

export const Box = styled.div`
    padding: ${Spacing.md};
    background-color: #ffffff;
    border-radius: 8px;
    border-top: solid 4px ${MagentaColor};
    border-bottom: solid 4px ${MagentaColor};
    box-shadow: 0 5px 15px 5px rgb(56 56 56 / 15%);
`;
