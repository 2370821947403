import * as fk from "@flixcheck/flixkit";
import { useCallback, useMemo } from "react";
import { ShopReportTable } from "./ShopReportTable";
import { ReportData, ReportDateSettings } from "../../interfaces/ShopReport";
import { TelekomButton } from "../../shared/style/Button";
import { MagentaColor } from "../../shared/style/Colors";
import axios from "axios";
import moment from "moment";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL!;

export interface ReportDataProps {
    report: ReportData;
    settings: ReportDateSettings;
}

export function ShopReportData({ report, settings }: ReportDataProps): JSX.Element {
    const { addNotification } = fk.useNotifications();
    const reportCreatedDate = useMemo(() => {
        return new Date().toISOString();
    }, []);

    const downloadPayload = useMemo(() => {
        const from: string = settings.from ? fk.getMidnight(settings.from).toISOString() : "";
        const to: string = settings.to ? fk.getAlmostMidnight(settings.to).toISOString() : "";
        return { from, to };
    }, [settings.from, settings.to]);

    const fetchExcelReport = useCallback(async () => {
        try {
            const response = await axios.get(`${BASE_URL}/report`, {
                responseType: "blob",
                headers: {
                    Authorization: sessionStorage.getItem("authToken"),
                    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                },
                params: {
                    from: downloadPayload.from,
                    until: downloadPayload.to
                }
            });

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            const date = moment().format("YYYY-MM-DD");
            link.download = `report_${date}.xlsx`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            addNotification({
                type: "error",
                message:
                    "Beim Herunterladen der Excel-Datei ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.",
                duration: fk.DefaultNotificationDurations.Medium
            });
            console.error("Fehler beim Excel-Download.", error);
        }
    }, [addNotification, downloadPayload.from, downloadPayload.to]);

    const reportDates = useMemo(
        () => ({
            created: reportCreatedDate,
            from: downloadPayload.from,
            to: downloadPayload.to
        }),
        [reportCreatedDate, downloadPayload.from, downloadPayload.to]
    );

    return (
        <>
            <fk.Row>
                <fk.Column xs={12}>
                    <fk.SectionWrapper type="plain" initialOpen={true}>
                        <fk.SectionBody>
                            <ShopReportTable data={report} dates={reportDates} />
                        </fk.SectionBody>
                    </fk.SectionWrapper>
                </fk.Column>
            </fk.Row>
            <fk.PageBreak />
            <fk.ScreenOnly>
                <fk.Stack
                    orientation="horizontal"
                    expansion="alongAxis"
                    alongAxisRule="expanded"
                >
                    <fk.Stack orientation="horizontal" alongAxisRule="center">
                        <TelekomButton
                            color={MagentaColor}
                            icon={<fk.Icon name="xlsx" />}
                            label="XLSX"
                            onClick={fetchExcelReport}
                        />
                    </fk.Stack>
                </fk.Stack>
            </fk.ScreenOnly>
        </>
    );
}
