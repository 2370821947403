import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Box } from "../../shared/style/Box";
import { Title } from "../../shared/style/Text";
import * as backendService from "../../services/backend.service";
import { CheckSendFields } from "./CheckSendFields";
import { AxiosError } from "axios";
import { useState } from "react";

export interface CheckSendFormParams {
    password: string;
}

export function CheckSendForm(params: CheckSendFormParams): JSX.Element {
    const { handleSubmit, errors, register } = useForm<CheckSendFields>();
    const { password } = params;
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = (data: CheckSendFields) => {
        setErrorMessage("");
        setLoading(true);
        backendService
            .sendAndCreateChecks(data, password)
            .then((answer) => {
                const { url } = answer;
                window.location.href = url;
            })
            .catch((error) => {
                if (error.isAxiosError) {
                    const axiosError = error as AxiosError;
                    if (axiosError.response?.data?.error) {
                        setErrorMessage(axiosError.response?.data?.error);
                        return;
                    }
                }
                alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <div className="App">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <Box>
                            <Title>Check erstellen</Title>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="formGender">
                                    <div
                                        className={
                                            errors.gender?.type === "required"
                                                ? "p-1 border rounded border-danger"
                                                : undefined
                                        }
                                    >
                                        <Form.Check
                                            inline
                                            label="Frau"
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            ref={register({ required: true })}
                                            id="genderFemale"
                                        />
                                        <Form.Check
                                            inline
                                            label="Herr"
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            ref={register({ required: true })}
                                            id="genderMale"
                                        />
                                    </div>
                                    <small className="text-danger">
                                        {errors.gender?.type === "required" &&
                                            "Bitte wählen Sie die Anrede der Kundin / des Kunden."}
                                    </small>
                                </Form.Group>
                                <Form.Group controlId="formFirstname">
                                    <Form.Label>Vorname (Kunde)</Form.Label>
                                    <Form.Control
                                        isInvalid={errors.firstname?.type === "required"}
                                        type="text"
                                        ref={register({ required: true })}
                                        name="firstname"
                                    />
                                    <small className="text-danger">
                                        {errors.firstname?.type === "required" &&
                                            "Bitte geben Sie den Vornamen der Kundin / des Kunden an."}
                                    </small>
                                </Form.Group>
                                <Form.Group controlId="formLastname">
                                    <Form.Label>Nachname (Kunde)</Form.Label>
                                    <Form.Control
                                        isInvalid={errors.lastname?.type === "required"}
                                        type="text"
                                        ref={register({ required: true })}
                                        name="lastname"
                                    />

                                    <small className="text-danger">
                                        {errors.lastname?.type === "required" &&
                                            "Bitte geben Sie den Nachnamen der Kundin / des Kunden an."}
                                    </small>
                                </Form.Group>
                                <Form.Group controlId="formCustomerNr">
                                    <Form.Label>Kundennummer</Form.Label>
                                    <Form.Control
                                        isInvalid={errors.customerNr?.type === "required"}
                                        type="text"
                                        pattern="[0-9]*"
                                        ref={register({ required: true })}
                                        name="customerNr"
                                    />

                                    <small className="text-danger">
                                        {errors.customerNr?.type === "required" &&
                                            "Bitte geben Sie die Kundenummer der Kundin / des Kunden an."}
                                    </small>
                                </Form.Group>
                                <Form.Group controlId="formMonile">
                                    <Form.Label>Mobilfunknummer (zum Versand des Checks an Kunden)</Form.Label>
                                    <Form.Control
                                        isInvalid={errors.mobile?.type === "required"}
                                        type="text"
                                        pattern="01[5-7]([0-9]{6,})"
                                        ref={register({ required: true })}
                                        name="mobile"
                                    />

                                    <small className="text-danger">
                                        {errors.mobile?.type === "required" &&
                                            "Bitte geben Sie die Mobilfunknummer der Kundin / des Kunden im Format 0171000000 an."}
                                    </small>
                                </Form.Group>
                                <Button variant="magenta" type="submit" disabled={isLoading}>
                                    Kundencheck senden &amp; eigenen öffnen
                                </Button>
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
