import React, { useState, useCallback } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CheckSendForm } from "./check/components/CheckSendForm";
import { AdLoginForm } from "./auth/components/LoginForm";
import { ShopReportCreate } from "./check/components/ShopReport";
import * as fk from "@flixcheck/flixkit";
import { BrowserRouter, Switch, Redirect, useHistory } from "react-router-dom";
import { LoginPage } from "./auth/components/pages/loginFormShopReport";
import { BackendModule } from "i18next";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "https://telekom.adapter.flixcheck.com";

const language = {
    known: ["de-DE", "de-CH", "en-GB", "fr-FR", "it-IT"],
    standard: "de-DE"
};

const i18nOptions = {
    fallbackLng: [language.standard],
    supportedLngs: language.known,
    whitelist: language.known,
    ns: ["report"],
    defaultNS: "report"
};

const localBackend: BackendModule = {
    type: "backend",
    read(language, namespace, callback) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-var-requires
        callback(null, require(`/public/assets/dictionaries/${namespace}/${language}.json`));
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    init(_services, _backendOptions, _i18nextOptions) {}
};

function App(): JSX.Element {
    const { initialized: i18nInitialized } = fk.useI18nInitialization(i18nOptions, localBackend);

    return i18nInitialized ? (
        <fk.App>
            <fk.ApiConfigProvider baseUrl={BASE_URL}>
                <fk.EventProvider>
                    <fk.NotificationsProvider>
                        <BrowserRouter>
                            <RouterAwareApp />
                        </BrowserRouter>
                    </fk.NotificationsProvider>
                </fk.EventProvider>
            </fk.ApiConfigProvider>
        </fk.App>
    ) : (
        <></>
    );
}

const PATHS = {
    shopReport: {
        login: "/shopReport/login",
        report: "/shopReport/report"
    },
    ad: {
        login: "/ad/login",
        form: "/ad/form"
    }
};

function RouterAwareApp(): JSX.Element {
    const history = useHistory();

    const [isLoggedIn, setLoggedIn] = useState({
        shopReport: !!sessionStorage.getItem("authToken"),
        adForm: false
    });

    const [adPassword, setAdPassword] = useState("");
    const onAdFormLoginSuccess = useCallback(
        (correctPassword: string) => {
            setLoggedIn((loggedIn) => ({
                ...loggedIn,
                adForm: true
            }));
            setAdPassword(correctPassword);
            history.push(PATHS.ad.form);
        },
        [history]
    );

    const onLoginReportSuccess = useCallback(() => {
        setLoggedIn((loggedIn) => ({
            ...loggedIn,
            shopReport: true
        }));
        history.push(PATHS.shopReport.report);
    }, [history]);

    return (
        <Switch>
            <fk.RouteWithTitle title="AD Login" path={PATHS.ad.login} exact={true}>
                <AdLoginForm onSuccess={onAdFormLoginSuccess}></AdLoginForm>
            </fk.RouteWithTitle>
            <fk.ProtectedRoute
                isAllowed={isLoggedIn.adForm}
                exact={true}
                redirectTo={PATHS.ad.login}
                title="AD Formular"
                path={PATHS.ad.form}
            >
                <CheckSendForm password={adPassword}></CheckSendForm>
            </fk.ProtectedRoute>

            <fk.RouteWithTitle title="Shop Report Login" path={PATHS.shopReport.login} exact={true}>
                <LoginPage onSuccess={onLoginReportSuccess} />
            </fk.RouteWithTitle>

            <fk.ProtectedRoute
                isAllowed={isLoggedIn.shopReport}
                exact={true}
                redirectTo={PATHS.shopReport.login}
                title="Shop Report"
                path={PATHS.shopReport.report}
            >
                <ShopReportCreate />
            </fk.ProtectedRoute>

            <Redirect exact={true} from="/" to={PATHS.ad.form} />
            <Redirect exact={true} from="/login" to={PATHS.ad.form} />
            <Redirect exact={true} from="/shopReport" to={PATHS.shopReport.report} />
            <Redirect exact={true} from="/shopReport/login" to={PATHS.shopReport.report} />
            <Redirect from="/" to={PATHS.ad.form} />
        </Switch>
    );
}

export default App;
