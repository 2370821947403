import styled from "styled-components";
import * as fk from "@flixcheck/flixkit";

export const TelekomButton = styled(fk.Button)<{ color: string }>`
    background-color: ${(props) => props.color};
    color: #ffffff !important;
    &:hover {
        background-color: #e370a3 !important;
    }

    &:disabled {
        background-color: #e370a3;
        cursor: not-allowed; 
        opacity: 0.6; 
    }
`;
