import * as fk from "@flixcheck/flixkit";
import React, { useCallback, useState } from "react";
import { LoginFormForPerformanceReport } from "../loginShopReport";
import axios, { AxiosResponse } from "axios";
import { Button } from "react-bootstrap";
import { LoginFormData } from "../../../interfaces/ShopReportLogin";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const LOGIN_URL = `${BASE_URL}/shopReport/login`;
const HEADERS = { "Content-Type": "application/json" };

interface LoginData {
    onSuccess: () => void;
}

function createBasicAuthToken(username: string, password: string): string {
    const token = btoa(`${username}:${password}`);
    return `Basic ${token}`;
}

export function LoginPage({ onSuccess }: LoginData) {
    const { addNotification } = fk.useNotifications();
    const [formData, setFormData] = useState<LoginFormData>({ username: "", password: "" });

    const showErrorNotification = useCallback((message: string) => {
        addNotification({
            type: "error",
            message,
            duration: fk.DefaultNotificationDurations.Medium,
        });
    }, [addNotification]);


    const handleLogin = useCallback(async (username: string, password: string) => {
        try {
            const response = await axios.post<AxiosResponse<string>>(LOGIN_URL, { username, password }, { headers: HEADERS });
            if (response.status === 200) {
                const token = createBasicAuthToken(username, password);
                sessionStorage.setItem("authToken", token);

                onSuccess();
                return response.data;
            } else {
                showErrorNotification("Login fehlgeschlagen!");
            }
        } catch (error) {
            showErrorNotification("Login fehlgeschlagen!");
        }
    }, [onSuccess, showErrorNotification]);

    const onLoginSubmit = useCallback(async () => {
        await handleLogin(formData.username, formData.password);
    }, [formData, handleLogin]);

    return (
        <fk.FullscreenLayout horizontalAlignment="center">
            <fk.Container>
                <fk.Row alongAxisRule="center">
                    <fk.Column xs={12} md={6}>
                        <fk.Form onSubmit={onLoginSubmit}>
                            <fk.Stack orientation="vertical" spacingFactor="xlarge">
                                <fk.Stack orientation="vertical" alongAxisRule="center" spacingFactor="medium">
                                    <fk.StackItem>
                                        <LoginFormForPerformanceReport data={formData} onChange={setFormData} />
                                    </fk.StackItem>
                                    <fk.StackItem>
                                        <fk.Stack orientation="horizontal" alongAxisRule="reversed">
                                            <Button variant="magenta" type="submit">
                                                Einloggen
                                            </Button>
                                        </fk.Stack>
                                    </fk.StackItem>
                                </fk.Stack>
                            </fk.Stack>
                        </fk.Form>
                    </fk.Column>
                </fk.Row>
            </fk.Container>
        </fk.FullscreenLayout>
    );
}