import axios, { AxiosError } from "axios";
import { CheckSendFields } from "../check/components/CheckSendFields";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "https://telekom.adapter.flixcheck.com";

function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}

function handleAxiosError(error: unknown): never | false {
    if (isAxiosError(error)) {
        const axiosError = error;
        if (axiosError.response?.status === 401) {
            return false;
        }
    }
    throw error;
}

export async function checkPassword(password: string): Promise<boolean> {
    try {
        await axios.get(BASE_URL + "/ad", {
            headers: {
                Authorization: "Password " + password
            }
        });
        return true;
    } catch (error) {
        if ((error as any).isAxiosError) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 401) {
                return false;
            }
        }
        throw error;
    }
}

export async function sendAndCreateChecks(
    data: CheckSendFields,
    password: string
): Promise<{ url: string }> {
    const answer = await axios.post(BASE_URL + "/ad", data, {
        headers: {
            Authorization: "Password " + password
        }
    });
    if (answer?.data?.url) {
        return {
            url: answer.data.url
        };
    } else {
        throw new Error("no url in answer");
    }
}

export async function getReport(from: string, until: string) {
    try {
        const response = await axios.get(BASE_URL + "/report", {
            headers: {
                Accept: "application/json",
                Authorization: sessionStorage.getItem("authToken"),
            },
            params: {
                from,
                until
            }
        });

        return response;
    } catch (error) {
        handleAxiosError(error);
    }
}
