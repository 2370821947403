import styled from "styled-components";
import * as fk from "@flixcheck/flixkit";
import { MagentaColor } from "./Colors";

export const TelekomTextInput = styled(fk.TextInput)`
    &:focus {
        border-color: ${MagentaColor} !important;
        outline: none !important;
    }

    &:hover {
        border-color: ${MagentaColor} !important;
        outline: none !important;
    }
`;
