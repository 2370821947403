import * as fk from "@flixcheck/flixkit";
import React, { useMemo, useCallback } from "react";
import { TelekomTextInput } from "../../shared/style/TextInput";
import { LoginFormData } from "../../interfaces/ShopReportLogin";

export interface LoginFormProps {
    errorsVisible?: boolean | undefined;
    submitted?: boolean | undefined;
    onValidChange?: ((valid: boolean) => void) | undefined;
    errors?: fk.FormFieldError<LoginFormData>[] | null | undefined;
    data: LoginFormData;
    onChange: (data: LoginFormData) => void;
}

export function LoginFormForPerformanceReport(props: LoginFormProps) {
    const onValidationRequest = useCallback((data: LoginFormData) => {
        const errors: fk.FormFieldError<LoginFormData>[] = [];

        if (!data.username) {
            errors.push({
                target: "username",
                message: "Dieses Feld muss ausgefüllt werden."
            });
        }

        if (!data.password) {
            errors.push({
                target: "password",
                message: "Dieses Feld muss ausgefüllt werden."
            });
        }
        return errors;
    }, []);

    const form = fk.useForm<LoginFormData>(
        useMemo<fk.FormOptions<LoginFormData>>(() => {
            return {
                data: props.data,
                errorsVisible: props.errorsVisible,
                submitted: props.submitted,
                onChange: props.onChange,
                onValidChange: props.onValidChange,
                errors: props.errors,
                onValidationRequest
            };
        }, [props, onValidationRequest])
    );

    const fieldErrors = useMemo(() => form.getAllErrors(), [form]);

    const fieldHandlers = useMemo(
        () => ({
            username: fk.createFormInputHandlers(form, "username"),
            password: fk.createFormInputHandlers(form, "password")
        }),
        [form]
    );

    return (
        <fk.Stack orientation="vertical" spacingFactor="wide">
            <fk.FormGroup>
                <fk.FormLabel text={"Username"} for="userLoginUsername" />
                <TelekomTextInput
                    className="user-login-username"
                    value={form.data.username}
                    id="userLoginUsername"
                    autoFocus={true}
                    {...fieldHandlers.username}
                />
                <fk.FormErrorList errors={fieldErrors.username ?? []} />
            </fk.FormGroup>
            <fk.FormGroup>
                <fk.FormLabel text={"Password"} for="userLoginPassword" />
                <TelekomTextInput
                    className="user-login-password"
                    value={form.data.password}
                    type="password"
                    id="userLoginPassword"
                    {...fieldHandlers.password}
                />
                <fk.FormErrorList errors={fieldErrors.password ?? []} />
            </fk.FormGroup>
        </fk.Stack>
    );
}

