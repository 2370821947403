import { useEffect, useState, useCallback } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Box } from "../../shared/style/Box";
import { Title } from "../../shared/style/Text";
import * as backendService from "../../services/backend.service";

export interface AdLoginFormProps {
    onSuccess: (correctPassword: string) => void;
}

interface LoginFormFields {
    password: string;
}

export function AdLoginForm(props: AdLoginFormProps): JSX.Element {
    const { register, handleSubmit, setValue } = useForm<LoginFormFields>();
    const [errorMessage, setErrorMessage] = useState("");
    const { onSuccess } = props;
    const [isLoading, setIsLoading] = useState(false);

    const handlePasswordCheck = useCallback(
        async (password: string): Promise<boolean> => {
            setIsLoading(true);
            const isValidPassword = await backendService.checkPassword(password);
            setIsLoading(false);
            return isValidPassword;
        },
        []
    );
    const onSubmit = useCallback(
        (data: LoginFormFields) => {
            setErrorMessage("");
            handlePasswordCheck(data.password)
                .then((isValid: boolean) => {
                    if (isValid) {
                        onSuccess(data.password);
                    } else {
                        setErrorMessage("Falsches Passwort.");
                    }
                })
                .catch(() => {
                    // TODO
                });
        },
        [handlePasswordCheck, onSuccess]
    );
    useEffect(() => {
        const urlPassword = new URLSearchParams(window.location.search).get("password");
        if (typeof urlPassword !== "string") {
            return;
        }
        setValue("password", urlPassword);
        onSubmit({
            password: urlPassword
        });
    }, [setValue, onSubmit]);
    return (
        <div className="App" >
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <Box>
                            <Title>Login</Title>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        ref={register({ required: true })}
                                        name="password"
                                    />
                                </Form.Group>
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                <Button variant="magenta" type="submit" disabled={isLoading}>
                                    {isLoading ? "Lade ..." : "Weiter"}
                                </Button>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
